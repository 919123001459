.Rsvp-pageWrapper {
  display: flex;
  flex-direction: column;
}

.Rsvp-wrapper {
  padding: 0 3.5rem;

  @include md {
    padding: 0 4rem;
  }

  @include lg {
    padding: 0 8rem;
  }

  @include xl {
    padding: 0 14.8rem;
  }
}

.Rsvp-menu {
  display: flex;
  align-items: center;
  height: 3rem;
  margin-top: 6.2rem;

  @include lg {
    margin-top: 8rem;
    height: 5.4rem;
  }
}

.Rsvp-link {
  @include large-text;
  position: fixed;
  right: 3.5rem;
  bottom: 3rem;
  z-index: 1;
  border-bottom: thin solid $white;
  padding: 0;
  background: none;
  color: $white;

  @include md {
    top: 25.3rem;
    bottom: auto;
    right: 4rem;
  }

  @include lg {
    top: 36rem;
    right: 8rem;
  }
  
  @include xl {
    top: 41rem;
    right: 14.8rem;
  }

  &:hover {
    color: $viridian;
    border-bottom: thin solid $viridian;
  }
}

.Rsvp-workcoLogo {
  height: 3rem;
 
  @include md {
    height: 4.7rem;
  }
 
  @include xl {
    height: 6.4rem;
  }
}

.Rsvp-backslash {
  line-height: 4rem;
  font-size: 4rem;
  margin-left: 2rem;
  margin-right: 1.5rem;

  @include md {
    line-height: 7rem;
    font-size: 7rem;
    margin-left: 4.5rem;
    margin-right: 3rem;
  }
}

.Rsvp-sxswLogo {
  height: 1.7rem;

  @include md {
    height: 3rem;
  }
}

.Rsvp-title {
  @include large-text;
  margin-top: 6.5rem;
  margin-bottom: 3.2rem;

  @include md {
    margin-top: 8.5rem;
    width: 60%;
  }
  
  @include lg {
    margin-top: 12.4rem;
    margin-bottom: 5rem;
  }
  
  @include xl {
    margin-top: 12.8rem;
    margin-bottom: 7.3rem;
  }
}

.Rsvp-event {
  @include small-text;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include md {
    width: 60%;
  }

  @include lg {
    flex-direction: row;
  }
}

.Rsvp-eventTime {
  margin-bottom: 3rem;

  @include lg {
    margin-bottom: 0;
    margin-right: 12.8rem;
  }
}

.Rsvp-description {
  margin: 16.4rem 0;
  
  @include md {
    padding-right: 0;
    margin: 12rem 0;
    width: calc(58% - 3.5rem);
  }

  @include lg {
    margin: 22.4rem 0;
    width: calc(58% - 8rem);
  }
  
  @include xl {
    margin: 32.5rem 0;
    width: calc(58% - 14.8rem);
  }
}

.Rsvp-description--title {
  @include medium-text;
}

.Rsvp-description--text {
  @include body-copy-two;
  margin-top: 2.5rem;

  @include lg {
    margin-top: 4rem;
  }
}

.Rsvp-bkBridgeWrapper {
  width: 100%;
  overflow: hidden;
  z-index: -1;

  @include md {
    margin-top: -10rem;
  }
}

.Rsvp-bkBridge {
  width: 100%;
  height: auto;
  z-index: -1;
}

.Rsvp-host {
  margin-bottom: 6rem;

  @include md {
    padding-left: 0; 
    width: calc(40% - 3.5rem);
    align-self: flex-end;
  }

  @include lg {
    width: calc(40% - 8rem);
  }

  @include xl {
    width: calc(40% - 14.8rem);
  }
}

.Rsvp-host--title {
  @include small-text;
  font-weight: $medium;
  margin-bottom: 2.4rem;

  @include md {
    margin-bottom: 3.2rem;
  }
}

.Rsvp-host--description {
  margin-bottom: 5.5rem;
  
  @include md {
    margin-bottom: 3.4rem;
  }
  
  @include md {
    margin-bottom: 4.4rem;
  }
  
  @include md {
    margin-bottom: 5.2rem;
  }
}

.Rsvp-host--link {
  text-decoration: none;
  color: inherit;
  border-bottom: thin solid $white;

  &:hover {
    color: $viridian;
    border-bottom: thin solid $viridian;
  }
}

.Rsvp-form {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin: 3.7rem 0 4.6rem;

  @include md {
    margin: 4rem 0 65rem;
  }

  @include lg {
    margin: 7rem 0 13.6rem;
  }
  
  @include xl {
    margin: 18.1rem 0 16.3rem;
  }
}

.Rsvp-form--title {
  @include large-text;
  margin-bottom: 2.2rem;
  
  @include lg {
    margin-bottom: 3.8rem;
  }

  @include xl {
    margin-bottom: 5.2rem;
  }
}

.Rsvp-inputWrapper {
  @include large-text;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 2.2rem;
  width: 100%;
  height: 4.5rem;

  @include md {
    height: 6.5rem;
  }
  
  @include lg {
    height: 10.7rem;
    margin-bottom: 3.8rem;
  }

  @include xl {
    margin-bottom: 5.2rem;
  }
}

.Rsvp-input {
  padding: 0;
  margin: 0;
  font: inherit;
  background-color: transparent;
  border: none;
  text-align: right;
  color: $white;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;

  &::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

  &::-webkit-input-placeholder {
    color: $white;
    opacity: 0.20;
  }
  &::-moz-placeholder {
    color: $white;
    opacity: 0.20;
  }
  &:-ms-input-placeholder {
    color: $white;
    opacity: 0.20;
  }
  &:-moz-placeholder {
    color: $white;
    opacity: 0.20;
  }
}

.Rsvp-input--error {
  color: rgba($white, 0.20);
}

.Rsvp-error {
  @include large-text;
  position: absolute;
  right: 0;
  display: none;
  top: 0;
}

.Rsvp-error--show {
  pointer-events: none;
  display: block;
  color: $viridian;
}

.Rsvp-form--submit {
  @include large-text;
  color: $white;
  background: $black;
  border: none;
  border-bottom: solid thin $white;
  padding: 0;

  &:disabled {
    color: $viridian;
    border-bottom: solid thin $viridian;
  }
}

.Rsvp-input--hidden {
  &::-webkit-input-placeholder {
    opacity: 0;
  }
  &::-moz-placeholder {
    opacity: 0;
  }
  &:-ms-input-placeholder {
    opacity: 0;
  }
  &:-moz-placeholder {
    opacity: 0;
  }
}

.Rsvp-hiddenInput {
  height: 0;
  width: 0;
}
