html {
  -webkit-font-smoothing: antialiased;
  font-family: $font-family-sans-serif;
  font-size: 10px;
}

// Mixins
@mixin large-text {
  font-size: 3.6rem;
  line-height: 4rem;

  @include md {
    font-size: 6rem;
    line-height: 7.2rem;
  }

  @include lg {
    font-size: 8.8rem;
    line-height: 9.6rem;
  }
  
  @include xl {
    font-size: 11.2rem;
    line-height: 12.8rem;
  }
}

@mixin medium-text {
  font-size: 2.4rem;
  line-height: 3.2rem;
  
  @include md {
    font-size: 3.2rem;
    line-height: 4rem;
  }

  @include lg {
    font-size: 4rem;
    line-height: 6rem;
  }
  
  @include xl {
    font-size: 6rem;
    line-height: 8rem;
  }
}

@mixin small-text {
  font-size: 1.6rem;
  line-height: 2.4rem;
  
  @include md {
    font-size: 2rem;
    line-height: 3.2rem;
  }

  @include lg {
    font-size: 2.4rem;
    line-height: 4rem;
  }

  @include xl {
    font-size: 3.2rem;
    line-height: 4.8rem;
  }
}

@mixin body-copy {
  font-size: 1.6rem;
  line-height: 2.4rem;

  @include lg {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }

  @include xl {
    font-size: 2.4rem;
    line-height: 4rem;
  }
}

@mixin body-copy-two {
  font-size: 1.6rem;
  line-height: 2.4rem;

  @include md {
    font-size: 2rem;
    line-height: 3.2rem;
  }

  @include xl {
    font-size: 3.2rem;
    line-height: 4.8rem;
  }
}


body {
  @include body-copy;
  color: $white;
}
