.Confirmation-page {
  @include small-text;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6.2rem 3.5rem 0;
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 6.2rem);

  @include md {
    padding: 6.5rem 4rem 0;
  }

  @include lg {
    min-height: calc(100vh - 7.8rem);
    padding: 7.8rem 6.8rem 0;
  }
  @include lg {
    min-height: calc(100vh - 8rem);
    padding: 8rem 14.7rem 0;
  }
}

.Confirmation-menu {
  display: flex;
  align-items: center;
  height: 3rem;

  @include lg {
    height: 5.4rem;
  }
}

.Confirmation-workcoLogo {
  height: 100%;
}

.Confirmation-backslash {
  line-height: 4rem;
  font-size: 4rem;
  margin-left: 2rem;
  margin-right: 1.5rem;

  @include lg {
    line-height: 7rem;
    font-size: 7rem;
    margin-left: 4.5rem;
    margin-right: 3rem;
  }
}

.Confirmation-sxswLogo {
  height: 1.7rem;

  @include md {
    height: 3rem;
  }
}

.Confirmation-title {
  @include medium-text;
  margin: 7.3rem 0 3.7rem;

  @include md {
    margin: 9.5rem 0 3.2rem;
  }
  
  @include lg {
    margin: 7.8rem 0 4.4rem;
  }
  
  @include xl {
    margin-top: 12.8rem 0 6rem;
  }
}

.Confirmation-theme {
  font-weight: $medium;
  margin-bottom: 1.8rem;

  @include md {
    margin-bottom: 2.4rem;
  }
  
  @include lg {
    margin-bottom: 1.6rem;
  }
  
  @include xl {
    margin-bottom: 3.7rem;
  }
}

.Confirmation-location {
  margin: 1.5rem 0 16.4rem;
  
  @include md {
    margin-bottom: 35rem;
  }
  
  @include lg {
    margin: 2.3rem 0 4.9rem;
  }
  
  @include xl {
    margin-bottom: 8.7rem;
  }
}

.Confirmation-bkBridgeWrapper {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  height: 100vh;
  @include md{
    right: -4rem;
  }
  
  @include lg{
    right: 0;
  }
}

.Confirmation-bkBridge {
  width: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  height: 22.8rem;

  @include md {
    height: 50%;
  }
  
  @include lg {
    height: 60%;
  }
  
  @include lg {
    height: 62%;
  }
}

.Confirmation-calendarPosition {
  margin-bottom: 4.9rem;

  @include md {
    margin-bottom: 6rem;
  }

  @include xl {
    margin-bottom: 9.7rem;
  }

  .addeventatc_dropdown {
    font-family: $font-family-sans-serif;
    border-radius: 0;

    span[role='menuitem'] {
      em {
        display: none;
      }
    }

    .copyx,
    .ateyahoo,
    .ateoutlookcom
     {
      display: none;
    }
  }
}

.Confirmation-button.addeventatc {
  @include small-text;
  display: inline;
  font-weight: 400;
  font-family: $font-family-sans-serif;
  background: none;
  color: $white !important;
  border: none !important;
  border-radius: 0;
  outline: none;
  box-shadow: none !important;
  border-bottom: thin solid $white !important;
  padding: 0;

  .addeventatc_icon {
    display: none;
  }

  &:hover {
    @include small-text;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $viridian !important;
    border-bottom: thin solid $viridian !important;
    background: none;
  }

  .Confirmation-hidden {
    display: none;
  }
}
