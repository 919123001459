@mixin md() {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl() {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
